import React from "react"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { Container, Row, Col } from "react-bootstrap"
import SEO from 'components/seo'
import "./templates.scss"
import CardItem from "../components/Cards/CardItem"
export default function HomeTemplate({
  data // this prop will be injected by the GraphQL query below.
}) {
  const { mdx, allMdx, site } = data; // data.mdx holds your post data
  const projets = allMdx.edges;
  const { body } = mdx;

  const items = [];
  projets.map((real, i ) => {
    items.push({
      title: real.node.frontmatter.title,
      url: real.node.fields.slug, 
      fluid: real.node.frontmatter.image && real.node.frontmatter.image.childImageSharp.fluid,
      categorie: real.node.frontmatter.categorie,
      excerpt: real.node.excerpt
    });
    return null;
  });

  return (
    <>
      <SEO title={site.siteMetadata.title} description={site.siteMetadata.description}/>
        <Container fluid className="container">
            <Row className="justify-content-md-center homepage-section">        
              <Col md="12">
                <MDXRenderer className="pages-content">
                  {body}
                </MDXRenderer>
              </Col>
            </Row>
            <Row className="justify-content-md-center homepage-section">
              <Col md="12">
                <h3>Sélection de projets</h3>
              </Col>
              {items.map((item, i) => (
                <Col key={i} md="4" lg="3">
                  <CardItem key={i} item={item}/>
                </Col>
              ))}
            </Row>
        </Container>
    </>
  )
}
export const pageQuery = graphql`
  query($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      body
    }
    site {
      siteMetadata {
        description
        title
      }
    }
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 24
      filter: {frontmatter: {type: {eq: "projets"}, featured: {eq: true}}}
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            categorie
            image {
              childImageSharp {
                fluid(maxWidth: 315, maxHeight: 315) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
        }
      }
    }

  }
`